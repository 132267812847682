<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/enterprise' }"
          >企业管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        v-loading="edit"
        :model="form"
        :rules="rules"
        label-width="200px"
        size="mini"
      >
        <el-form-item label="企业名称：" label-position="left" prop="name">
          <el-input v-model="form.name" class="input" style="width: 220px" />
        </el-form-item>

        <el-form-item label="是否默认：" label-position="left" prop="type">
          <el-radio v-model="form.is_default" :label="0" border>否</el-radio>
          <el-radio v-model="form.is_default" :label="1" border>是</el-radio>
        </el-form-item>

        <el-form-item label="状态：" label-position="left" prop="status">
          <el-radio v-model="form.status" :label="0" border>停用</el-radio>
          <el-radio v-model="form.status" :label="1" border>启用</el-radio>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "add",
  data() {
    return {
      loading: false,
      form: {
        is_default: 1,
        status: 1,
      },
      edit: false,
      rules: {
        name: [{ required: true, message: "请输入路径", trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapActions("enterprise", ["addEnterprise", "getEnterprise"]),
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const { res_info } = await this.addEnterprise(this.form);
            if (res_info !== "ok") return;
            this.$message.success("创建成功");
            this.$router.go(-1);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async init() {
      try {
        this.edit = true;
        const { data } = await this.getEnterprise({
          id: this.$route.params.id,
        });
        this.form = data;
      } finally {
        this.edit = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
</style>